import dynamic from 'next/dynamic';
import {useState} from 'react';
import {Grid, Box} from '@jobseeker/lotus/lib/foundations';
import Typography from '@mui/material/Typography';
import {Skeleton} from '@mui/material';

const Authentication = dynamic(
  () =>
    import('@jobseeker/client-harness/lib/components/AuthenticationHarness'),
  {
    loading: () => (
      <Box
        display={'block'}
        margin={{
          xxs: '0px 25px',
          sm: '0px 35px',
          md: '0px 55px',
          lg: '0px 70px',
        }}
        justifyContent="center"
      >
        <Skeleton
          sx={{
            display: {xxs: 'none', md: 'block'},
            height: {md: 20},
            width: '50%',
            alignSelf: 'left',
            mt: 1,
          }}
          animation="pulse"
          variant="rectangular"
        />
        <Skeleton
          sx={{
            display: {xxs: 'none', md: 'block'},
            height: {md: 40},
            width: '100%',
            mt: 2,
          }}
          animation="pulse"
          variant="rectangular"
        />
        <Skeleton
          sx={{
            display: {xxs: 'none', md: 'block'},
            height: {md: 40},
            width: '100%',
            mt: 2,
          }}
          animation="pulse"
          variant="rectangular"
        />
        <Skeleton
          sx={{
            height: {xxs: 60, md: 45},
            width: '100%',
            mt: 3,
            borderRadius: 6,
          }}
          animation="pulse"
          variant="rectangular"
        />
        <Skeleton
          sx={{
            display: {xxs: 'none', md: 'block'},
            height: {md: 20},
            width: '60%',
            mt: 3,
          }}
          animation="pulse"
          variant="rectangular"
        />
        <Skeleton
          sx={{
            display: {xxs: 'none', md: 'block'},
            height: {md: 20},
            width: '100%',
            mt: 2,
            mb: 1,
          }}
          animation="pulse"
          variant="rectangular"
        />
        <Skeleton
          sx={{
            display: {xxs: 'block', md: 'none'},
            height: {md: 35},
            width: '100%',
            mt: {md: 4, lg: 1},
          }}
          animation="pulse"
          variant="rectangular"
        />
      </Box>
    ),
    ssr: false,
  }
);

const AuthenticationMod = () => {
  const [showQuote, setShowQuote] = useState(true);
  const [changeTop, setChangeTop] = useState(0);

  return (
    <Grid
      container
      sx={{
        position: 'absolute',
        top: showQuote
          ? changeTop
            ? `${changeTop}%`
            : '6%'
          : changeTop
          ? `${changeTop}%`
          : '22%',
        minWidth: '320px',
        minHeight: showQuote ? (changeTop ? '550px' : '520px') : 0,
        width: {
          xxs: '95%',
          md: '45%',
          lg: '35%',
        },
        zIndex: 2,
      }}
    >
      <Grid
        item
        sx={{
          margin: {xxs: '130px auto', md: '0px auto'},
          background: 'rgba(255,255,255,0.8)',
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          boxShadow: 2,
          backgroundColor: 'rgba(255,255,255,.94)',
        }}
      >
        {showQuote && (
          <Box
            width={{sm: '100%', md: '90%'}}
            margin={{md: '10px auto', lg: '15px auto'}}
            textAlign="center"
            color="secondary.main"
          >
            <Typography
              component="p"
              fontSize={{md: '18px', lg: '20px'}}
              lineHeight="1.5"
              fontStyle="italic"
              fontWeight={600}
            >
              {`"Choose a job you love, and you will never have to work a day in your life."`}
            </Typography>
            <Typography
              component="p"
              sx={{
                margin: '0 auto 10px',
                fontSize: {md: '14px', lg: '16px'},
                fontWeight: 400,
                fontStyle: 'italic',
              }}
            >
              {'- Confucius'}
            </Typography>
          </Box>
        )}
        <Authentication
          setChangeTop={setChangeTop}
          setShowQuote={setShowQuote}
        />
      </Grid>
    </Grid>
  );
};

export default AuthenticationMod;
