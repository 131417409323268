import dynamic from 'next/dynamic';
import {useSelector, shallowEqual} from 'react-redux';
import {Box, Grid} from '@jobseeker/lotus/lib/foundations';
import {useIsLargeDevice, useWrappedTheme} from '@jobseeker/lotus/lib/hooks';
import useInterceptor from '@jobseeker/common-core/lib/hooks/useInterceptor';
import InjectSearchOverlay from '@components/SearchInjection';
import FeaturedEmployer from './FeaturedEmployer';
import DynamicQuoteBox from '@jobseeker/lotus/lib/components/Qoute';

import type {AppState} from '@jobseeker/common-core/lib/types';

const CompanyWorkWith = dynamic(() => import('./CompanyWorkWith'));

const FindOpportunity = dynamic(() => import('./FindOpportunity'));

const EnhanceSkill = dynamic(() => import('./EnhanceSkill'));

const LearningPartner = dynamic(() => import('./LearningPartner'));

const DownloadApp = dynamic(() => import('./DownloadApp'));

const Home = () => {
  const isLargeXl = useIsLargeDevice('xl');
  const isLargeOnly = useIsLargeDevice('md');
  const isAboveMobile = useIsLargeDevice('sm');
  const isAboveLargeDevice = useIsLargeDevice(2560);

  const theme = useWrappedTheme();

  const isSearchEnabled = useSelector(
    (state: AppState) => state.app.layout.searchOverlay,
    shallowEqual
  );

  const {
    home: {
      section: {
        featuredEmployer,
        quote,
        companyWorkWith,
        findMyOpportunity,
        enhanceSkills,
        learningPartner,
        downloadApp,
      },
    },
  }: /* eslint-disable @typescript-eslint/no-explicit-any */ any =
    useInterceptor() || {};

  return (
    <Box display="flex" alignItems={'center'} justifyContent={'center'}>
      <Grid
        maxWidth={1440}
        width={'100%'}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <InjectSearchOverlay isSearchEnabled={isSearchEnabled} />
        {/* <!--- Featured Employer ---> */}
        <FeaturedEmployer
          theme={theme}
          heading={featuredEmployer['heading']}
          isAboveMobile={isAboveMobile}
          isLargeXl={isAboveLargeDevice}
          isLargeOnly={isLargeOnly}
          slotId={featuredEmployer['slotId']}
          eventTag={featuredEmployer['eventTag']}
        />
        {/* <!--- Quote Section ---> */}
        <Box display={{xxs: 'none', md: 'flex'}}>
          <DynamicQuoteBox author={quote['author']} qoute={quote['text']} />
        </Box>

        {/* <!--- Companies you might work with ---> */}
        {companyWorkWith?.enabled && (
          <CompanyWorkWith
            eventTag={companyWorkWith['eventTag']}
            slotId={companyWorkWith['slotId']}
            heading={companyWorkWith['heading']}
            isLargeXl={isLargeXl}
            isAboveMobile={isAboveMobile}
            theme={theme}
          />
        )}
        {/* <!--- Find the opportunity ---> */}
        {findMyOpportunity?.enabled && (
          <FindOpportunity
            eventTag={findMyOpportunity['eventTag']}
            heading={findMyOpportunity['heading']}
            isLargeXl={isLargeXl}
            isAboveMobile={isAboveMobile}
            theme={theme}
          />
        )}
        {/* <!--- Enhance My Skills ---> */}
        {enhanceSkills?.enabled && (
          <EnhanceSkill
            eventTag={enhanceSkills['eventTag']}
            slotId={enhanceSkills['slotId']}
            heading={enhanceSkills['heading']}
            isLargeXl={isLargeXl}
            isAboveMobile={isAboveMobile}
            theme={theme}
          />
        )}
        {/* <!--- Learning Partner ---> */}
        {learningPartner?.enabled && (
          <LearningPartner
            eventTag={learningPartner['eventTag']}
            slotId={learningPartner['slotId']}
            heading={learningPartner['heading']}
            isLargeXl={isLargeXl}
            isAboveMobile={isAboveMobile}
            theme={theme}
          />
        )}
        {/* <!--- Download App Banner ---> */}
        {downloadApp?.enabled && (
          <DownloadApp
            appImage={downloadApp['appImage']}
            downloadText={downloadApp['downloadText']}
            dotSvg={`url(${downloadApp['dotSvg']}) left center/7px no-repeat`}
            isLargeXl={isLargeXl}
            isAboveMobile={isAboveMobile}
            heading={downloadApp['heading']}
            backgroundImg={{
              large: `url(${downloadApp.backgroundImg.large}) center bottom/100% no-repeat`,
              small: `url(${downloadApp.backgroundImg.small}) center bottom/100% no-repeat`,
            }}
            downloadAppContent={downloadApp['downloadAppContent']}
            downloadAppButton={downloadApp['downloadAppButton']}
            theme={theme}
          />
        )}
      </Grid>
    </Box>
  );
};

export default Home;
