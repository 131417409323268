import {useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Box} from '@jobseeker/lotus/lib/foundations';
import {openUrl} from '@jobseeker/lotus/lib/utils';
import Authentication from '../AuthenticationMod';
import useLoadAds from '@jobseeker/common-core/lib/hooks/useLoadAds';
import {Skeleton} from '@mui/material';
import Ribbon from '@jobseeker/lotus/lib/elements/Ribbon';
import {handleTracking} from '@jobseeker/common-core/lib/utils';

import type {FeaturedEmployerType} from '../types';
import type {AppState, AdsElementType} from '@jobseeker/common-core/lib/types';

const FeaturedEmployer = (props: FeaturedEmployerType) => {
  const [featuredEmployer, setFeaturedEmployer] = useState(
    Array<AdsElementType>
  );

  const {eventTag, heading, theme, slotId} = props || {};

  const featureEmployer = useSelector(
    (state: AppState) => state.ads.featureEmployer,
    shallowEqual
  );

  const {device} = useSelector(
    (state: AppState) => state.app.layout,
    shallowEqual
  );

  useLoadAds(
    slotId,
    setFeaturedEmployer,
    {},
    false,
    !featureEmployer[0]?.imageUrl
  );

  const loadedFeaturedEmployers = featureEmployer[0]?.imageUrl
    ? featureEmployer
    : featuredEmployer;

  return (
    <Box paddingTop={{xxs: theme.spacing(4), md: theme.spacing(8)}}>
      {loadedFeaturedEmployers[0]?.imageUrl ? (
        <Box
          sx={{
            height: {xxs: theme.spacing(69.375), md: theme.spacing(74.5)},
            width: '100%',
            background: `url(${loadedFeaturedEmployers[0]?.imageUrl}) no-repeat`,
            display: 'flex',
            backgroundSize: 'cover',
            justifyContent: 'center',
            position: 'sticky',
          }}
        >
          <Authentication />
          {loadedFeaturedEmployers[0]?.companyLogo && (
            <Box
              sx={{
                position: 'absolute',
                right: theme.spacing(0),
                width: device === 'desktop' ? 'fit-content' : '100%',
                top: device === 'desktop' ? theme.spacing(7) : 'auto',
                bottom: device === 'desktop' ? 'auto' : theme.spacing(2),
                cursor: 'pointer',
                zIndex: 1,
              }}
              onClick={event => {
                event?.preventDefault();
                openUrl(
                  `${loadedFeaturedEmployers[0]?.link}?ref=home`,
                  '_blank'
                );
                handleTracking(eventTag, {
                  event_category: 'engagement',
                  Label: loadedFeaturedEmployers[0]?.title,
                  Value: '0',
                });
              }}
            >
              <Ribbon
                label={heading}
                imgUrl={loadedFeaturedEmployers[0]?.companyLogo}
                isAboveMobile={device === 'desktop'}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            height: {xxs: theme.spacing(69.375), md: theme.spacing(74.5)},
            width: '100%',
            background: {
              xxs: `url(data:image/webp;base64,UklGRjAAAABXRUJQVlA4TCQAAAAv30GaAAdQ8mbXvP8BIUHi//NGIvqf8Z///Oc///nPf/7z/w8=) no-repeat center`,
              md: `url(data:image/webp;base64,UklGRlwAAABXRUJQVlA4TE8AAAAv78XgAAdQ8mbXvP8BAEX6/58i+p/63//+97///e9///vf//73v//973//+9///ve///3vf//73//+97///e9///vf//73v//973//+/8DAA==) no-repeat center`,
            },
            display: 'flex',
            backgroundSize: 'cover',
            justifyContent: 'center',
            position: 'sticky',
          }}
        >
          <Box
            display={'block'}
            justifyContent="center"
            sx={{
              background: '#ffffff',
              width: '450px',
              margin: {
                xxs: '160px 16px',
                md: '60px',
              },
              padding: '50px',
            }}
          >
            <Skeleton
              sx={{
                display: {xxs: 'none', md: 'block'},
                height: {md: 20},
                width: '50%',
                alignSelf: 'left',
                mt: 2,
              }}
              animation="pulse"
              variant="rectangular"
            />
            <Skeleton
              sx={{
                display: {xxs: 'none', md: 'block'},
                height: {md: 35},
                width: '100%',
                mt: 2,
              }}
              animation="pulse"
              variant="rectangular"
            />
            <Skeleton
              sx={{
                display: {xxs: 'none', md: 'block'},
                height: {md: 35},
                width: '100%',
                mt: 1,
              }}
              animation="pulse"
              variant="rectangular"
            />
            <Skeleton
              sx={{
                height: {xxs: 60, md: 50},
                width: '100%',
                mt: 2,
              }}
              animation="pulse"
              variant="rectangular"
            />
            <Skeleton
              sx={{
                display: {xxs: 'none', md: 'block'},
                height: {md: 20},
                width: '80%',
                mt: 1,
              }}
              animation="pulse"
              variant="rectangular"
            />
            <Skeleton
              sx={{
                display: {xxs: 'block', md: 'none'},
                height: {md: 35},
                width: '100%',
                mt: 1,
              }}
              animation="pulse"
              variant="rectangular"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FeaturedEmployer;
